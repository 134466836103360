import { Form, Image, Button, Space, Row, Col, Typography} from "antd"
import { useShareDispatch, useShareSelector, actionsApi } from "../../shared"
import { CitiesInput } from '../../components'
import "./index.css"
const {Title, Text}= Typography

const Login = () => {

    const appDispatcher = useShareDispatch()
    const [form] = Form.useForm()
    const { loading } = useShareSelector(state => state?.login)
    const login = () => {
        appDispatcher(actionsApi?.login(form.getFieldsValue(['email', 'password'])))
    }

    return (
        <div 
            className='login-form-cover'
            >
            <div className="center">
                <Row className="login-form border-grey">
                    <Col md={11} sm={24} xs={24}>
                        <Space 
                            direction='vertical' 
                            className='py-5 my-2 px-4 width-100'
                            size={15}
                            >
                            <div className="justify-center">
                                <Image
                                    src={"./assets/images/logo-2.png"}
                                    alt='Hair Plus International'
                                    preview= {false}
                                />
                            </div>
                            <Title 
                                level={5} 
                                className="my-0 text-center repla-color"
                                >
                                Login To Access Your Dashboard
                            </Title>
                            <Form
                                form={form}
                                layout="vertical"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={login}
                                style={{ width: '100%' }}
                            >
                                <Row gutter={[0, 4]}>
                                    <Col span={24} className='border'>
                                        <CitiesInput
                                            name="email"
                                            label="Email"
                                            required
                                            message='please enter email'
                                            size='large'
                                            placeholder='user@hprwp.com'
                                            value={form.getFieldValue('email') || ''}
                                        />
                                    </Col>
                                    <Col span={24} className='border'>
                                        <CitiesInput
                                            name="password"
                                            type='password'
                                            label="Password"
                                            size='large'
                                            required
                                            message='please enter password'
                                            placeholder='*********'
                                            value={form.getFieldValue('password') || ''}
                                        />
                                    </Col>
                                    <Col span={24}>
                                        <Button 
                                            type="primary"
                                            htmlType="submit" 
                                            loading={loading}
                                            size="large"
                                            block
                                            >
                                            Login
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Space>
                    </Col>
                    <Col 
                        md={13} sm={0} xs={0}
                        className="light-grey-bg flex-col-align-end"
                        style={{ backgroundImage: "url(/assets/images/login-screen.png)" }}
                        >
                            <Title 
                                level={5} 
                                className="text-center light-grey-text"
                                >
                                Version 1.1.6 Beta Release
                            </Title>
                    </Col>
                </Row>
            </div>
            <div className="center">
                <Text strong className="my-0 py-2 text-center text-white">
                    © 2022 - {new Date().getFullYear()}. All rights reserved by <a href="https://replatechnologies.com/" rel="noreferrer"  target="_blank" >Repla Technologies</a>
                </Text>
            </div>
        </div>
    )
}
export default Login